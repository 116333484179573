// store/index.ts
import { InjectionKey } from 'vue'
import RootState from './interface'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import menu from './modules/menu'
import { AllState } from './interface'

export default createStore<RootState>({
  state: {
    test: '',
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    menu,
  },
})
export const key: InjectionKey<Store<RootState>> = Symbol('vue-store')

export function useStore<T = AllState>() {
  return baseUseStore<T>(key)
}
