import { Module } from 'vuex'
import RootState from '@/store/interface'
import CurrentMenuState, { Menu } from './interface'
import Cookies from 'js-cookie'

const menuModule: Module<CurrentMenuState, RootState> = {
  namespaced: true,
  state: {
    currentMenu: {
      path: '/',
      label: '主页',
      name: 'home',
      icon: 'HomeFilled',
      url: 'Home/HomeView',
    },
    tmpMenu: [],
    tabsList: [
      {
        path: '/',
        label: '主页',
        name: 'home',
        icon: 'HomeFilled',
        url: 'Home/HomeView',
      },
    ],
    isCollapse: false,
    menu: [
      {
        path: '/',
        label: '主页',
        name: 'home',
        icon: 'HomeFilled',
        url: 'Home/HomeView',
      },
    ],
  },
  mutations: {
    setMenu(state: { menu: Menu[] }, val: Menu[]) {
      state.menu = val
      Cookies.set('menu', JSON.stringify(val))
    },
    clearMenu(state: { menu: Menu[]; tabsList: Menu[] }) {
      state.menu = []
      state.tabsList = [
        {
          path: '/',
          label: '主页',
          name: 'home',
          icon: 'HomeFilled',
          url: 'Home/HomeView',
        },
      ]
      Cookies.set('tagList', JSON.stringify(state.tabsList))
      Cookies.remove('menu')
    },
    addMenu(state, router) {
      if (!Cookies.get('menu')) {
        return
      }
      const menu = JSON.parse(Cookies.get('menu'))
      state.menu = menu
      let tmpMenu = [
        {
          name: 'admin',
          path: '/',
          component: () => import(`@/views/MainView.vue`),
          children: [] as Menu[],
        },
      ]
      menu.forEach((item: Menu) => {
        if (item.children) {
          item.children = item.children.map((item: Menu) => {
            item.component = () => import(`@/views/${item.url}.vue`)
            return item
          })
          tmpMenu[0].children.push(...item.children)
        } else {
          item.component = () => import(`@/views/${item.url}.vue`)
          tmpMenu[0].children.push(item)
        }
      })
      tmpMenu.forEach((item) => {
        router.addRoute(item)
      })
      tmpMenu = []
      console.log('router added')
    },
    selectMenu(state, val) {
      state.currentMenu = val
      if (val.name !== 'home') {
        const result = state.tabsList.findIndex((item) => item.name === val.name)
        result === -1 ? state.tabsList.push(val) : ''
        Cookies.set('tagList', JSON.stringify(state.tabsList))
      }
      // val.name === 'home' ? (state.currentMenu = null) : (state.currentMenu = val)
    },
    getTags(state) {
      if (Cookies.get('tagList')) {
        const tagList = JSON.parse(Cookies.get('tagList'))
        state.tabsList = tagList
      }
    },
    closeTab(state: { tabsList: Menu[] }, menu: Menu) {
      const result = state.tabsList.findIndex((item) => item.name === menu.name)
      state.tabsList.splice(result, 1)
      Cookies.set('tagList', JSON.stringify(state.tabsList))
    },
    isCollpase(state: { isCollapse: boolean }) {
      state.isCollapse = !state.isCollapse
    },
  },
  getters: {
    getTab(state: { tabsList: Menu[] }) {
      return state.tabsList
    },
    getIsCollapse(state: { isCollapse: boolean }) {
      return state.isCollapse
    },
    getMenu(state: { menu: Menu[] }) {
      return state.menu
    },
    getCurrentMenu(state: { currentMenu: Menu | null }) {
      return state.currentMenu
    },
  },
}

export default menuModule
