import axios from 'axios'

const http = axios.create({
  timeout: 30000,
})

http.defaults.baseURL = 'https://ysdadminapi.5uld.com'
// http.defaults.baseURL = '/allow'

http.interceptors.request.use(
  (config) => {
    {
      config.headers!.token = window.sessionStorage.getItem('token') || ''
      config.headers!['Content-Type'] = 'application/json'

      return config
    }
  },
  (error) => {
    console.log(error)
  }
)

http.interceptors.response.use(
  (response) => {
    const res = {
      status: response.status,
      data: response.data,
    }
    return res
  },
  (error) => {
    console.log(error)
  }
)

export default http
