<template>
  <router-view />
</template>

<style lang="scss">
#app {
  height: 100vh;
  width: 100vw;
}
</style>
