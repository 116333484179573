import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/LoginView.vue'),
  },
  {
    path: '/order',
    name: '',
    component: () => import(`@/views/MainView.vue`),
    children: [
      {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/Order/OrderDetailView.vue'),
      },
      {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('@/views/Product/ProductDetailView.vue'),
      },
      {
        path: '/addProduct',
        name: 'addProduct',
        component: () => import('@/views/Product/AddProductView.vue'),
      },
      {
        path: '/editProduct',
        name: 'editProduct',
        component: () => import('@/views/Product/EditProductView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

let runOne = true
router.beforeEach((to, from, next) => {
  if (runOne) {
    runOne = false
    store.commit('menu/addMenu', router)
    next({ ...to, replace: true })
  } else {
    // 防止刷新后vuex里丢失标签列表tagList
    store.commit('menu/getTags')
    const token = window.sessionStorage.getItem('token')
    // 过滤登录页，防止死循环
    if (!token && to.name !== 'login') {
      next({ name: 'login' })
    } else {
      next()
    }
  }
})

export default router
